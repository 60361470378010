// src/apolloClient.js
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'cross-fetch';

const graphqlUrl = 'https://cms.protoproto.studio/api'; // Verify this URL
const authToken = 'I3DjQ1Fqam6Jxp3lS-DVWBvLpmv4eBjM'; // Confirm the token

const headers = {
  Authorization: `${authToken}`,
  "Content-Type": "application/json",
  "Accept": "application/json",
};

const httpLink = new HttpLink({
  method: "POST",
  "timeout": 0,
  uri: graphqlUrl,
  fetch,
  headers,
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: httpLink,
  cache,
});

export default client;
