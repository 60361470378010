import React from "react"

const ShoppingBag = ({ className = "w-6 h-6" }) => (
  <svg
    className={`${className} fill-current`}
    width="24"
    height="27"
    viewBox="0 0 24 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.9995 0.595215C9.25352 0.595215 6.99952 2.84921 6.99952 5.59521V6.59521H2.06152L1.99952 7.53321L0.999524 25.5332L0.936523 26.5952H23.0615L22.9995 25.5322L21.9995 7.53222L21.9365 6.59521H16.9995V5.59521C16.9995 2.84921 14.7455 0.595215 11.9995 0.595215ZM11.9995 2.59521C12.7952 2.59521 13.5582 2.91129 14.1208 3.47389C14.6835 4.0365 14.9995 4.79957 14.9995 5.59521V6.59521H8.99952V5.59521C8.99952 4.79957 9.31559 4.0365 9.8782 3.47389C10.4408 2.91129 11.2039 2.59521 11.9995 2.59521ZM3.93652 8.59521H6.99952V11.5952H8.99952V8.59521H14.9995V11.5952H16.9995V8.59521H20.0625L20.9375 24.5952H3.06252L3.93652 8.59521Z" />
  </svg>
)

export default ShoppingBag
